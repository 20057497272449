import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Header } from "./layout/Header";
import { Footer } from "./layout/Footer";
import { Home } from "./pages/Home";
import { LanguageProvider } from "./context/LanguageContext";
import "./global.css";
import { Experience } from "./pages/Experience";
import { Booking } from "./pages/Booking";
import CookieConsent from "./components/CookieConsent";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { DiscountBanner } from "./components/DiscountedBanner";
import { ComingSoon } from "./pages/ComingSoon";

function App() {
  return (
    <LanguageProvider>
      <Router>
        {/* <Header /> */}
        <main className="w-screen">
          <Routes>
            <Route path="/" element={<ComingSoon />} />
            {/* <Route path="/" element={<Home />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/experience/:id" element={<Experience />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
          </Routes>
          {/* <DiscountBanner />
          <CookieConsent /> */}
        </main>
        {/* <Footer /> */}
      </Router>
    </LanguageProvider>
  );
}

export default App;
