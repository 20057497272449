import React, { createContext, useContext, useState, useEffect } from "react";

const LanguageContext = createContext();

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export const LanguageProvider = ({ children }) => {
  // Retrieve stored language from localStorage or default to Swedish
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "sv"
  );

  useEffect(() => {
    // Store language preference in localStorage
    localStorage.setItem("language", language);
  }, [language]);

  const toggleLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
