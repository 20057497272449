import React from "react";
import { Phone, Mail, Linkedin, MapPin } from "lucide-react";
import { useLanguage } from "../context/LanguageContext";
import gulaVillan from "../assets/gulavillan.jpg"; // Background Image
import Logo from "../assets/Logo.png"; // Your Logo

export const ComingSoon = () => {
  const { language } = useLanguage();

  return (
    <section
      id="coming-soon"
      className="relative w-full h-screen overflow-hidden flex items-center justify-center bg-black"
    >
      {/* 🖼️ Background Image */}
      <div className="absolute inset-0">
        <img
          src={gulaVillan}
          alt="Gula Villan"
          className="w-full h-full object-cover"
        />
      </div>

      {/* 🌚 Dark Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/80 to-black/90"></div>

      {/* 🌟 Content */}
      <div className="relative z-10 text-center text-white p-6 lg:p-20 max-w-3xl mx-auto animate-fadeIn space-y-6">
        {/* Logo with Culeus Text */}
        <div className="flex items-center justify-center bg-white/90 rounded-xl p-4 shadow-lg mb-6 w-fit mx-auto space-x-4">
          <img src={Logo} alt="Culeus Logo" className="w-10 h-10" />
          <span className="text-[#162C18] text-4xl font-bold">Culeus</span>
        </div>

        {/* Heading */}
        <h1 className="text-4xl md:text-6xl font-bold mb-4 tracking-tight text-base-100 animate-fadeIn">
          {language === "sv" ? "Kommer Snart" : "Coming Soon"}
        </h1>

        {/* Description */}
        <p className="text-lg md:text-xl mb-6 text-neutral-300 animate-fadeIn">
          {language === "sv"
            ? "Vår hemsida håller på att byggas. Kontakta oss gärna via telefon eller e-post under tiden."
            : "Our website is under construction. Feel free to reach out via phone or email in the meantime."}
        </p>

        {/* Contact Buttons */}
        <div className="flex flex-col md:flex-row justify-center gap-4 mb-8 animate-fadeIn">
          {/* Phone Button */}
          <a
            href="tel:0707401411"
            className="flex items-center gap-2 bg-[#A4E76D] text-[#162C18] py-3 px-8 rounded-lg text-lg font-semibold hover:bg-[#A4E76D]/90 transition-all duration-300 shadow-xl"
          >
            <Phone />
            {language === "sv" ? "Ring oss" : "Call Us"}
          </a>

          {/* Email Button */}
          <a
            href="mailto:info@culeus.se"
            className="flex items-center gap-2 bg-[#162C18] text-white py-3 px-8 rounded-lg text-lg font-semibold hover:bg-[#162C18]/90 transition-all duration-300 shadow-xl"
          >
            <Mail />
            {language === "sv" ? "E-posta oss" : "Email Us"}
          </a>
        </div>

        {/* Social Media */}
        <div className="flex justify-center space-x-6 mt-6 animate-fadeIn">
          <a
            href="https://www.linkedin.com/company/culeus"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 bg-base-100  rounded-full shadow-md hover:shadow-lg transition-all duration-300 hover:scale-105"
          >
            <Linkedin className="w-8 h-8 text-primary " /> {/* LinkedIn Blue */}
          </a>
        </div>

        {/* Branding */}
        <div className="text-sm text-neutral-400 mt-6 animate-fadeIn">
          {language === "sv"
            ? "Följ oss på sociala medier för uppdateringar."
            : "Follow us on social media for updates."}
        </div>

        {/* Address */}
        <div className="mt-12 animate-fadeIn flex items-center justify-center space-x-2 text-neutral-300">
          <MapPin className="w-5 h-5 text-[#A4E76D]" />
          <a
            href="https://www.google.com/maps/search/?api=1&query=Almas+Allé+9,+756+51+Uppsala"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#A4E76D] transition-colors duration-300"
          >
            Almas Allé 9, 756 51 Uppsala
          </a>
        </div>
      </div>
    </section>
  );
};
